<template>
  <keep-alive :include="include">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  data() {
    return {
      include: "orderOfflinePay",
    };
  },
  mounted() {},
};
</script>

<style></style>
